
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Information1 from "@/components/dropdown/Information1.vue";
import ElCurrencyInput from "@/components/CurrencyInput.vue";
import { ElLoading } from "element-plus";
import moment from "moment";
import { Action } from "vuex-module-decorators";
import { object } from "yup/lib/locale";
import ElInfo from "@/components/ElInfo.vue";

export default defineComponent({
name: "form",
components: {
  Field,
  Form,
},
async mounted() {
  this.init();
},
data() {
  const schema = Yup.object({});
  return {
    schema:schema,
    radio_button_items:[
      {id:1 , name:'choose 1'},
      {id:2 , name:'choose 2'},
      {id:3 , name:'choose 3'},
    ],
    select_items:[
      {id:1 , name:'choose 1'},
      {id:2 , name:'choose 2'},
      {id:3 , name:'choose 3'},
    ]
  };
},
setup() {
  return {};
},
methods: {
  init() {
    setCurrentPageBreadcrumbs("Example Pages", []);
  },
  onSubmit(values){
    console.log(values);
  }
},
});
